



































































































































































































































































































































































































.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.field-row-label {
  padding: calc(0.438rem + 1px) 0;
}

// 只显示第一个 .error
.validation-provider {
  display: none;

  &.error {
    display: block;
  }

  &.error ~ &.error {
    display: none;
  }
}

.modal-body {
  .row {
    margin-bottom: 1rem;
  }
  .row:last-of-type {
    margin-bottom: 0;
  }
}
